import React from 'react';

import { Formik } from 'formik';
import { navigate } from "@reach/router";

import Layout from '../../../components/layout/Layoutexport';

import { StaticImage } from "gatsby-plugin-image";





const siteVerOpts = [

  {
    label: 'Internacional',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/contactenos/',

  },


];

const langOpts = [

  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: '/export/en/contact/',

  },


];


const seo={

  title: "Contáctenos hoy mismo!",
  desc:  "Utilice el formulario para enviarnos su información rápidamente, nos pondremos en contacto con usted muy pronto.",
  image: "",
  pathname: "/export/es/contacto/",
  alternateEn: "/export/en/contact/",
  alternateEs: "/export/es/contacto/",
  alternateEsec: "/contactenos/",
  alternateDefault: "/export/es/contacto",

}


const encode = (data) => {

  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

}

const Contacto = ({location}) => {



return(

  <Layout location={location} crumbLabel="Contacto"
          siteVerOpts={siteVerOpts} langOpts={langOpts}
          alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
            title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<div className="bg-fixed" >

    <StaticImage src="../../../images/banner-contactenos.jpg"
                className="object-contain"
                alt="Solicite una cotización con Unilimpio"
                placeholder="blurred"
                layout="fullWidth"
                 />


</div>


<section className="bg-white flex justify-center">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-4xl font-semibold text-primary-default ">¡Solicite una cotización AHORA!</h1>
    </div>
  </div>
</section>

<section id="contacto" className="relative bg-white mt-2">
<div className="relative bg-white">
  <div className="absolute inset-0">
    <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-200"></div>
  </div>
  <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
    <div className="bg-gray-200 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
      <div className="max-w-lg mx-auto">

        <dl className="mt-8 text-2xl text-primary-default font-normal">
          <div>
            <dd>
              <p>Gracias por su interés en Unilimpio S.A. Estaremos gustosos de atenderle para brindarle mayor información acerca de nuestros productos de higiene profesional.</p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
      <div className="max-w-lg mx-auto lg:max-w-none">

      <Formik
        initialValues={{
          nombre: '',
          compania: '',
          pais: '',
          email: '',
          telefono: '',
          mensaje: ''

        }}
        onSubmit={(values, actions) => {
/* this juicy bit of code for submitting thru post using formik is thanks to:
Derek Spaulding
https://www.derekaspaulding.com/blog/simple-contact-form-with-gatsby-formik-and-netlify/
*/
          fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "lead-Es", ...values })
          })
          .then(() => {


          })
          .catch(() => {
            alert('Se produjo un error al procesar su solicitud. Por favor revise la información suministrada y vuelva a intentarlo.}');
          })
          .finally(() => {
            actions.setSubmitting(false)
            navigate(`/export/es/gracias/`)
          })

        }}
        validate={values => {

          const errors = {};

          if (!values.nombre) {
            errors.nombre = 'Requerido';
          } else if (values.nombre.length < 5) {
            errors.nombre = 'Por favor ingrese su nombre completo.';
          }


          if (!values.compania) {
            errors.compania = 'Requerido';
          } else if (values.compania.length < 5) {
            errors.compania = 'La información proporcionada no parece ser válida.';
          }

          if (!values.pais) {
            errors.pais = 'Requerido';
          }

          if (!values.email) {
            errors.email = 'Requerido';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Dirección de correo inválida.';
          }

          if (!values.telefono) {
            errors.telefono = 'Requerido';
          } else if (values.telefono.length < 6) {
            errors.telefono = 'La información proporcionada parece ser inválida. Por favor revise.';
          }

          if (!values.mensaje) {
            errors.mensaje = 'Requerido';
          } else if (values.mensaje.length < 20 ) {
            errors.mensaje = 'Por favor describa su requerimiento con mayor detalle para poder atenderle mejor.';
          } else if (values.mensaje.lenght > 500) {
            errors.mensaje = 'Máximo 500 caracteres.';
          }

          return errors;

        }}

      >
          {formik => (

        <form name="lead-Es" onSubmit={formik.handleSubmit} method="POST" data-netlify="true" netlify-honeypot="bot-field" className="grid grid-cols-1 gap-y-6">

        <input type="hidden" name="form-name" value="lead-Es" />


          <div>
            <label htmlFor="nombre" className="sr-only">Nombre</label>
            <input type="text" name="nombre" id="nombre"
                  onChange={formik.handleChange} value={formik.values.nombre}  onBlur={formik.handleBlur}
                  autocomplete="name"
                  className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.nombre && formik.errors.nombre ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  placeholder="Nombre"/>
                  {formik.touched.nombre && formik.errors.nombre ? <p className="text-red-500 text-xs italic">{formik.errors.nombre}</p> : null}

          </div>

          <div>
            <label htmlFor="compania" className="sr-only">Compañia</label>
            <input id="compania" name="compania" type="text"
            onChange={formik.handleChange} value={formik.values.compania}  onBlur={formik.handleBlur}

            autocomplete="company"
            className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.compania && formik.errors.compania ? 'border-red-500' : 'border-gray-300'} rounded-md`}
            placeholder="Compañia"/>
            {formik.touched.compania && formik.errors.compania ? <p className="text-red-500 text-xs italic">{formik.errors.compania}</p> : null}

          </div>
          <div>
            <label htmlFor="pais" className="sr-only">Pais</label>
            <select name="pais" id="pais"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    autocomplete="country"
                    className={`block w-full shadow-sm sm:text-sm ${formik.touched.pais ? '' : 'text-gray-500'} focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.pais && formik.errors.pais ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                    placeholder="País"

            >
                <option value="" selected>País</option>
                <option value="AD">Andorra</option>
                <option value="AR">Argentina</option>
                <option value="BO">Bolivia</option>
                <option value="BR">Brasil</option>
                <option value="CO">Colombia</option>
                <option value="CR">Costa Rica</option>
                <option value="CU">Cuba</option>
                <option value="CL">Chile</option>
                <option value="EC">Ecuador</option>
                <option value="SV">El Salvador</option>
                <option value="ES">España</option>
                <option value="GT">Guatemala</option>
                <option value="HN">Honduras</option>
                <option value="MX">México</option>
                <option value="NI">Nicaragua</option>
                <option value="PA">Panamá</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Perú</option>
                <option value="PR">Puerto Rico</option>
                <option value="DO">República Dominicana</option>
                <option value="UY">Uruguay</option>
                <option value="VE">Venezuela</option>
                <option value="OTRO">Otro (especificar en el comentario)</option>
            </select>
            {formik.touched.pais && formik.errors.pais ? <p className="text-red-500 text-xs italic">{formik.errors.pais}</p> : null}

          </div>
          <div>
            <label htmlFor="email" className="sr-only">Email</label>
            <input id="email" name="email" type="email"
            onChange={formik.handleChange} value={formik.values.email}  onBlur={formik.handleBlur}

            autocomplete="email"
            className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md`}
            placeholder="Correo"/>
            {formik.touched.email && formik.errors.email ? <p className="text-red-500 text-xs italic">{formik.errors.email}</p> : null}

          </div>
          <div>
            <label htmlFor="telefono" className="sr-only">Teléfono</label>
            <input type="text" name="telefono" id="telefono"
            onChange={formik.handleChange} value={formik.values.telefono}  onBlur={formik.handleBlur}

            autocomplete="tel"
            className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.telefono && formik.errors.telefono ? 'border-red-500' : 'border-gray-300'} rounded-md`}
            placeholder="Teléfono"/>
            {formik.touched.telefono && formik.errors.telefono ? <p className="text-red-500 text-xs italic">{formik.errors.telefono}</p> : null}

          </div>

          <div>
            <label htmlFor="mensaje" className="sr-only">Mensaje</label>
            <textarea id="mensaje" name="mensaje"
                      onChange={formik.handleChange} onBlur={formik.handleBlur}
                      aria-describedby="mensaje_description" rows="4" className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.mensaje && formik.errors.mensaje ? 'border-red-500' : 'border-gray-300'} rounded-md`}>
              {formik.values.mensaje}
            </textarea>
                      {formik.touched.mensaje && formik.errors.mensaje ? <p className="text-red-500 text-xs italic">{formik.errors.mensaje}</p> : null}


          </div>
          <div>
            <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-default hover:bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Enviar
            </button>
          </div>
          <div className="invisible">
            <label htmlFor="bot-field">
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </div>
        </form>
      )}
 </Formik>
      </div>
    </div>
  </div>
</div>
</section>



<section className="bg-gray-100">
<div className="bg-gray-100">
  <div className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
    <h2 className="text-3xl font-extrabold text-primary-default">OFICINA MATRIZ</h2>
    <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
      <div>
        <h3 className="text-2xl font-medium text-primary-lighter">Quito-Ecuador</h3>
        <p className="mt-2 text-xl text-primary-default">
          <span className="block">De los eucaliptos E1-140 y psje. Juncos</span>
          <span className="block">Sector Parque de los recuerdos</span>
          <span className="block">170307</span>
          <span className="block">WhatsApp: +593 98 795 8215</span>
        </p>
      </div>
    </div>
  </div>
</div>
</section>






    </Layout>

  );
}

export default Contacto;
